import React, {  useEffect, useState, useMemo } from "react";
import Form from "react-bootstrap/Form";
import Autocomx from "../Autocomx";
import Excelxxx from "../Excelxxx";
import { useLazyGetCimaDirectorProviderQuery} from "../../libs/redux/slices/cimaxxxx/cimaxxxxApiSlice";
const Metadata = ({ GEST0004, setJsonmetx, inputValue, jsonmetx }) => {
  let cObject = "";
  let bRequired = GEST0004.BANOBLXX === "SI" ? true : false;
  let cLabel = bRequired ? GEST0004.METDESXX + " *" : GEST0004.METDESXX;
  let objectMetadata = { ...GEST0004 };
  const [findCimaDirectionProviders] = useLazyGetCimaDirectorProviderQuery();
  const [options, setOptions] = useState([]);

   // Mapeo de campos a sus respectivos endpoints y valores a concatenar
 const endpoints = useMemo(() => ({
  PRSIDIREC: {
    query: findCimaDirectionProviders,
    values: ['PRSIDIREC', 'CLIPROCB', 'APLVECIX', 'PIEIDXXX', 'PAIIDXXX', 'DEPIDXXX', 'CIUIDXXX'],
  },
  DIRECPRO: {
    query: findCimaDirectionProviders,
    values: ['DIRECPRO','PRODIRXX'],
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  //Hook encargado de consultar los Metadatos tipo Select que cuentan con Funcion
  useEffect(() => {
    if (endpoints[GEST0004.METIDXXX]) {
      const endpoint = endpoints[GEST0004.METIDXXX];
      switch (GEST0004.METIDXXX) {
        case "PRSIDIREC":
        case "DIRECPRO":
          if (jsonmetx.CLIIDXXX && jsonmetx.PROIDXXX) {
            const filterEmpresa = `filters[CLIIDXXX]=${jsonmetx.CLIIDXXX}&filters[IDPROXXX]=${jsonmetx.PROIDXXX}`;
            endpoint.query(filterEmpresa)
              .then((response) => {
                if (response?.data?.data) {
                  const formatData = response.data.data.map((direction) => {
                    const valuesToUse = [...new Set(endpoint.values.map(val =>
                      val === GEST0004.METIDXXX && direction.PRODIRXX ? direction.PRODIRXX : direction[val] || ''
                    ))];

                    const value = valuesToUse.join(',-,');
                    return {
                      value,
                      label: direction.PRODIRXX,
                    };
                  });
                  setOptions(formatData);
                }
              })
              .catch(() => {
                setOptions([]);
              });
          }
          break;
        default:
          break;
      }
    }

    if (objectMetadata.METIDXXX === "USERDIFE" && (jsonmetx[objectMetadata.METIDXXX] === "" || jsonmetx[objectMetadata.METIDXXX] === undefined)) {
      setJsonmetx(objectMetadata.METIDXXX, "NO", "NO");
    }
    // eslint-disable-next-line
  }, [jsonmetx, endpoints, GEST0004]);

  switch (objectMetadata.METTIPXX) {
    case "BOOLEANO":
      cObject = (
        <Form.Check
          type="checkbox"
          name={objectMetadata.METIDXXX}
          label={cLabel}
          required={bRequired}
          onChange={(e) => {
            const key = objectMetadata.METIDXXX;
            setJsonmetx(key, e.target.value);
          }}
        ></Form.Check>
      );
      break;
    case "NUMERO":
    case "TEXTO":
      let type = objectMetadata.METTIPXX === "NUMERO" ? "number" : "text";
      cObject = (
        <>
          <Form.Label>{cLabel}</Form.Label>
          <Form.Control
            value={inputValue[objectMetadata.METIDXXX]}
            type={type}
            name={objectMetadata.METIDXXX}
            required={bRequired}
            onChange={(e) => {
              if (e.target.value !== "") {
                const key = objectMetadata.METIDXXX;
                setJsonmetx(key, e.target.value);
              }
            }}
          ></Form.Control>
        </>
      );
      break;
    case "FECHA":
      cObject = (
        <>
          <Form.Label>{cLabel}</Form.Label>
          <Form.Control
            value={inputValue[objectMetadata.METIDXXX]}
            required={bRequired}
            type="date"
            name={objectMetadata.METIDXXX}
            onChange={(e) => {
              if (e.target.value !== "") {
                const key = objectMetadata.METIDXXX;
                setJsonmetx(key, e.target.value);
                e.target.value = e.target.value.split("/").reverse().join("-");
              }
            }}
          ></Form.Control>
        </>
      );
      break;
      case "LISTASELECCION":
        if (endpoints[GEST0004.METIDXXX]) {
          const result = endpoints[GEST0004.METIDXXX].values
            .map(val => jsonmetx[val])
            .filter(val => val)
            .join(',-,');
          cObject = (
            <>
              <Form.Label>{cLabel}</Form.Label>
              <Form.Select
                value={result || ""}
                required={bRequired}
                name={GEST0004.METIDXXX}
                onChange={(e) => {
                  const key = GEST0004.METIDXXX;
                  setJsonmetx(key, e.target.value);
                }}
              >
                <option value="">[SELECCIONE]</option>
                {options.map((option, key) => (
                  <option value={option.value} key={`option-${GEST0004.METIDXXX}-${key}`}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </>
          );
        } else {
          cObject = (
            <>
              <Form.Label>{cLabel}</Form.Label>
              <Form.Select
               value={objectMetadata.METIDXXX === 'CAPDAFAC' || objectMetadata.METIDXXX === 'USERDIFE' ? jsonmetx[objectMetadata.METIDXXX] : inputValue[objectMetadata.METIDXXX]}
                required={bRequired}
                name={GEST0004.METIDXXX}
                onChange={(e) => {
                  const key = GEST0004.METIDXXX;
                  setJsonmetx(key, e.target.value);
                }}
              >
                <option value="">[SELECCIONE]</option>
                {GEST0004.METOPXXX.split(",").map((option, key) => (
                  <option value={option} key={`option-${GEST0004.METIDXXX}-${key}`}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </>
          );
        }
        break;
    case "LISTASELECCIONFUNCION":
      cObject = (
        <>
          <Form.Label>{cLabel}</Form.Label>
          <Autocomx
            objectMetadata={objectMetadata}
            setJsonmetx={setJsonmetx}
            isMultiple={false}
            inputValue={inputValue}
            bRequired={bRequired}
            jsonmetx={jsonmetx}
          />
        </>
      );
      break;
    case "LISTASELECCIONFUNCIONMULTIPLE":
      cObject = (
        <>
          <Form.Label>{cLabel}</Form.Label>
          <Autocomx
            objectMetadata={objectMetadata}
            setJsonmetx={setJsonmetx}
            isMultiple={true}
            inputValue={inputValue}
            bRequired={bRequired}
            jsonmetx={jsonmetx}
          />
        </>
      );
      break;
    case "HORA":
      cObject = (
        <>
          <Form.Label>{cLabel}</Form.Label>
          <Form.Control
            value={inputValue[objectMetadata.METIDXXX]}
            required={bRequired}
            type="time"
            name={objectMetadata.METIDXXX}
            onChange={(e) => {
              if (e.target.value !== "") {
                const key = objectMetadata.METIDXXX;
                setJsonmetx(key, e.target.value);
                e.target.value = e.target.value.split("/").reverse().join("-");
              }
            }}
          ></Form.Control>
        </>
      );
      break;
      case "EXCEL":
        cObject = (
          <>
            <Form.Label>{cLabel}</Form.Label>
            <Excelxxx
              objectMetadata={objectMetadata}
              setJsonmetx={setJsonmetx}
              isMultiple={false}
              inputValue={inputValue}
              bRequired={bRequired}
              jsonmetx={jsonmetx}
            />
          </>
        );
        break;
    default:
      cObject = "";
      break;
  }
  return cObject;
};

export default Metadata;
